<script>
import Navbar from "@/components/navbar0";
import Footer from "@/components/footer";


import { MessageCircleIcon, MailIcon,UserIcon,BookmarkIcon,
  GitBranchIcon, SkipForwardIcon } from "vue-feather-icons";


import store from "@/store/store";

export default {
  name: "EmailToCandidates",
  components: {
    Navbar,
    Footer,

    MessageCircleIcon, MailIcon,UserIcon,BookmarkIcon,
    GitBranchIcon, SkipForwardIcon,
  },
  mounted() {
   //this.getAllVacancies();
  //  this.loadTemplateList();
    this.getAllJobApplications();
  },



  methods: {

    updateFilter(filterName) {
      this.filterCategory = filterName;
    },





    removeResume(ix) {
      console.log('inside removeResume ix = ' + ix);
      this.resumes.splice(ix,1);
    },

    goToResume(x) {
      //let route = this.$router.resolve({path: '/resume/'+x});
      //window.open(route.href, '_blank');

      this.in_sql.user_id = store.state.user_id;
      this.in_sql.vacancy_id = this.$route.params.vacancy_id;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/job-application/get-list?access_token=' + store.state.token,
        data: this.in_sql,
      }).then((res) => {
        this.job_applications = res.data.list;
      });

    },
    getResumeFile(x){
      this.in_sql.id = x;
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/job-application/get-resume?access_token=' + store.state.token,
        data: this.in_sql,
        responseType: 'blob',
      }).then((res) => {
        var fileURL = URL.createObjectURL(res.data);
        window.open(fileURL);
        /*var FILE = window.URL.createObjectURL(new Blob([res.data]));

        var docUrl = document.createElement('x');
        docUrl.href = FILE;
        docUrl.setAttribute('download', 'file.pdf');
        document.body.appendChild(docUrl);
        docUrl.click();*/
      });
    },

    getAllJobApplications() {


      this.in_sql.user_id = store.state.user_id;
      this.in_sql.vacancy_id = this.$route.params.vacancy_id;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/job-application/get-list?access_token=' + store.state.token,
        data: this.in_sql,
      }).then((res) => {
        this.job_applications = res.data.list;
      });

    },

    getResumes(vacancy_id,funnel_stage_order_id) {
      this.resumes=[];
      console.log('inside get getResumes')
      this.input_save_vacancy.user_id = store.state.user_id;
      this.input_save_vacancy.vacancy_id = vacancy_id;
      this.input_save_vacancy.funnel_stage_order_id = funnel_stage_order_id;
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy/get-resumes-minimal?access_token=' + store.state.token,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.resumes = res.data.resumes;
        console.log(this.resumes)

        /*
        var ev = document.createEvent('KeyboardEvent');
        ev.initKeyboardEvent(
            'keydown', true, true, window, false, false, false, false, 27, 0);
        //ev.initKeyboardEvent("keypress",27);

        ev.initKeyboardEvent("keypress",       // typeArg,
            true,             // canBubbleArg,
            true,             // cancelableArg,
            null,             // viewArg,  Specifies UIEvent.view. This value may be null.
            false,            // ctrlKeyArg,
            false,            // altKeyArg,
            false,            // shiftKeyArg,
            false,            // metaKeyArg,
            27,               // keyCodeArg,
            0);              // charCodeArg);
            document.body.dispatchEvent(ev);
         */

      });
      this.input_sql_apply.funnel_stage_order_id = funnel_stage_order_id;
      this.input_sql_apply.vacancy_id = vacancy_id;
    },




    updateResume(e) {
      console.log(e);

      this.resumes[e.index].isSelected = e.isSelected

    },

    indexListen(e){
      this.input_sql_apply.resume_id = this.resumes[e].resume_id;

      this.input_sql_apply.user_id = store.state.user_id;


      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/get-email-to-send?access_token=' + store.state.token,
        data: this.input_sql_apply,
      }).then((res) => {
        console.log(res.data);
        this.showEmail = res.data;
      }).catch((err) => {
        console.error(err);
      });

    },












    selectVacancy(vacancyObj) {
      this.jobFunnelsClone = [];
      this.jobFunnelsClone.push(vacancyObj);
      this.stepOneCheck = true;
    },




    selectStep(x) {
      this.stepSelected = x;
    },

  },
  data(){
    return{

      in_sql: {
        id:1,
        user_id:1,
        vacancy_id:1,
      },
      job_applications: [],

      stepOneCheck:false,
      stepTwoCheck:false,
      stepThreeCheck:false,
      stepFourCheck:false,

      filterCategory: "all",

      stepSelected: 1,

      hover:false,
      activeVacancies:[],
      jobFunnels: [],
      jobFunnelsClone: [],
      input_save_vacancy: {
        type_id: 1,
        user_id: 1,
        vacancy_id:0,
        toDelete: false,
        name: '',
        id: 0,
        status: 0,
        funnel_stage_order_id:1,
      },

      jobDescription : {
        user_id: 0 ,
        job_title: '',
        job_city: '',
        job_type: '',
        job_company: '',
        job_salary_from: 0,
        job_salary_to: 0,
        job_salary_currency: '',
        createFlag: 0,

      },
      resumes:[],
      selectAllCheckbox: false,
      emailTemplates_Options : [
        {id:1,value:'По дате добавления резюме'},
        {id:2,value:'По дате обновления резюме'},
        {id:3,value:'По убыванию зарплат'},
        {id:4,value:'По возрастанию зарплат'},
        {id:5,value:'По убыванию возраста'},
        {id:6,value:'По возрастанию возраста'},
      ],
      emailTemplates_Selected : {id:1,value:'По дате добавления резюме'},

      emailTemplates: [],


      content: '<p><strong>Обязанности:</strong></p><ul><li><br></li><li><br></li></ul><p><strong>Требования:</strong></p><ul><li><br></li><li><br></li></ul><p><strong>Условия:</strong></p><ul><li><br></li><li><br></li></ul><p><br></p>',
      editorOption: {

        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ]
        }
      },


      selected_vacancy_id:0,
      selected_funnel_stage_order_id:0,

      input_save_template: {
        user_id: 1,
        selectForm: 0,

        template_id:1,
        template_name: '',

        from_name: '',
        from_email: '',
        subject: 'sa',
        emailBody: '',
        bcc_email: '',

        name_new: '',

        toDelete: 0,
      },

      input_sql_apply: {
        funnel_stage_order_id: 1,
        funnel_stage_name: '',

        vacancy_id: 1,
        vacancy_name: '',

        user_id: 1,
        resumes: [],
        emailTemplate: {},
        updateFlag:0,

        to_email: '',
        email_to: '',
        resume_id:1,
      },

      showEmail: {
        to_name: '',
        to_email: '',
        from_name: '',
        from_email: '',
        subject: '',
        emailBody_str: '',
      },

    }
  },


}
</script>

<style scoped>

</style>


<template>

  <div>
    <Navbar :nav-light="true" />



    <section class="section">
      <div class="container">
        <div class="row">
          <ul class="col container-filter list-unstyled categories-filter text-center mb-0" id="filter">





          </ul>
        </div>
        <!--end row-->
        <div class="container">
          <div class="mt-60 mb-60">
          <h2 class="text-center"> Отклики на вакансию: {{jobDescription.job_title}} </h2>
          </div>














            <div class="table-responsive bg-white shadow rounded">

              <table class="table mb-0 table-center">
                <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Полное имя</th>
                  <th scope="col">Номер телефона</th>
                  <th scope="col">Email</th>

                  <th scope="col">Загруженное CV</th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="(job_application,ix) in job_applications">
                  <td>{{ix+1}}.</td>
                  <td>  {{job_application.name}}</td>
                  <!--<td @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
                    <a  @click="getResumeFile(job_application.id)" class="text-primary ml-3"
                    >{{job_application.id}}<i class="uil uil-arrow-right"></i></a>
                  </td> -->
                  <td>  {{job_application.phone}}</td>

                 <!-- <td>  {{job_application.name}}</td> -->
                  <td>  {{job_application.email}}</td>
                 <!-- <td>  {{job_application.phone}}</td> -->
                  <td @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
                    <a  @click="getResumeFile(job_application.id)" class="text-primary ml-3"
                    >{{job_application.id}}<i class="uil uil-arrow-right"></i></a>
                  </td>
                  <!--<td @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                      @click="removeResume(ix)">
                    <i class="uil uil-times text-danger"></i>
                  </td>-->
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>









        </div>
      </div>
    </section>



    <Footer />

  </div>

</template>
